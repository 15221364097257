<template>
  <div>
    <c-table-feedback-admin
      :title="title"
      :items="getItems"
      :columns="columns"
      :totalRows="totalRows"
      :perPage="perPage"
      :currentPage="currentPage"
      :api="api"
      @changeCurrentPage="changeCurrentPage"
      @changePerPage="changePerPage"
    ></c-table-feedback-admin>
  </div>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import table from "@/libs/table";
import CTableFeedbackAdmin from "@/components/CTableFeedbackAdmin.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    CTableFeedbackAdmin,
    ToastificationContent,
  },
  data() {
    return {
      title: "Riwayat Kuesioner",
      api: "questionnaire-session/admin",
      dir: false,
      items: [],
      totalRows: 0,
      perPage: 10,
      limit: 0,
      orderBy: "createdAt",
      sortBy: "desc",
      currentPage: 1,
      search: "",
      columns: [
        { key: "no", label: "#", sortable: false },
        { key: "sessionCode", label: "kode", sortable: true },
        { key: "blockName", label: "nama blok", sortable: true },
        { key: "lecturerName", label: "nama dosen", sortable: true },
        { key: "category", label: "type", sortable: true },
        { key: "session", label: "sesi", sortable: true },
        { key: "studentCount", label: "jumlah mhs mengisi", sortable: true },
        { key: "status", label: "status", sortable: true },
        {
          key: "action",
          label: "aksi",
          class: "text-center",
          actions: { show: true },
        },
      ],
    };
  },
  mounted() {
    const _ = this;
    if (_.$route.params) {
      if (_.$route.params.variant) {
        _.toastNotification(
          _.$route.params.variant,
          "BellIcon",
          _.$route.params.text
        );
      }
    }
  },
  methods: {
    getItems(ctx, callback) {
      table.getItems(ctx, callback, this);
    },
    changeCurrentPage(page) {
      table.changeCurrentPage(this, page);
    },
    changePerPage(perPage) {
      table.changePerPage(this, perPage);
    },
    toastNotification(variant, icon, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Pemberitahuan",
          icon,
          text,
          variant,
        },
      });
    },
  },
};
</script>
